import { template as template_78da74071c034f3196db0d1353937e81 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_78da74071c034f3196db0d1353937e81(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
