import { template as template_24804a3429fc4412a11ab9e052d5730e } from "@ember/template-compiler";
const FKLabel = template_24804a3429fc4412a11ab9e052d5730e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
