import { template as template_cde3cb4f4b5d44bcbf6cf3aea8ba1f72 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cde3cb4f4b5d44bcbf6cf3aea8ba1f72(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
